import React from "react";
export default class Hello extends React.Component {
  constructor(props) {
    super(props);
    this.state = [];
  }
  render() {
    return (
      <div className="Hello">
        <h1>VoidfulDawn's Tool Tree</h1>
        <h2>I am sometimes bored and here you can find blossoms of my boredom.</h2>
      </div>
    );
  }
}
