import { IconContext } from "react-icons";
import { FaGithub, FaTwitter, FaInstagram } from "react-icons/fa";
import React from "react";
export default class Linkbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = [];
  }
  render() {
    return (
      <IconContext.Provider
        value={{ color: "black", size: "2em", className: "global-class-name" }}
      >
        <div>
          <a href="https://github.com/VoidfulDawn">
            <FaGithub />
          </a>
          <a href="https://twitter.com/">
            <FaTwitter />
          </a>
          <a href="https://www.instagram.com/ahoomanoidtree/">
            <FaInstagram />
          </a>
        </div>
      </IconContext.Provider>
    );
  }
}
