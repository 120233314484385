import React from "react";
import { Navbar, Nav } from "react-bootstrap";
export default class NavbarHead extends React.Component {
  constructor(props) {
    super(props);
    this.state = [];
  }
  render() {
    return (
      <div>
        <Navbar bg="dark" variant="dark">
          <Navbar.Brand href="#home">Hoomanoid</Navbar.Brand>
          <Nav className="mr-auto">
            <Nav.Link href="/macro">Macro Creator</Nav.Link>
            <Nav.Link href="https://github.com/SaDoKe">SaDoKe</Nav.Link>
            <Nav.Link>Soon more</Nav.Link>
          </Nav>
        </Navbar>
      </div>
    );
  }
}
