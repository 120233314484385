import React from "react";
import "./styles.css";
import Hello from "./Components/Hello";
import NavbarHead from "./Components/Navbar";
import Linkbar from "./Components/Linkbar";
import "bootstrap/dist/css/bootstrap.css";
export default function App() {
  return (
    <div className="App">
      <NavbarHead />
      <Hello />
      <Linkbar />
    </div>
  );
}
